import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import { Modal, notification } from "antd";
 import Layout from "../components/header";
 import axios from "axios";
 import { graphql } from 'gatsby'
 import HeaderLight from "./../components/headerLight";
 import Footer from "./../components/footer";
import { element } from "prop-types";
import { navigate } from "@reach/router";
import moment from 'moment'
import { Spin } from "antd";
// import SweetAlert from "sweetalert-react";
// import Pagination from "react-js-pagination";
// require("bootstrap/dist/css/bootstrap.css");
// require("sweetalert/dist/sweetalert.css");
 require("../css/login.css");
 var passwordHash = require('password-hash');
 var  otpGenerator =require('otp-generator');


 //var globalemail;

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        loading: false,
        displayEmail: "none",
        displayEmailInvalid: "none",
        displayPassword:"none",
        //data:false,
        displayalreadyexit:false,
        alreadyhaveemail:"none",
        emailpws:false,
        invalidPws:"none",
        redirect:"none",
        OTP:"",
        invalidOTP:"none",
        
        visible:false,
        email:"",
        displayResetPass:"none",
  
        nowTime:"",
        otpSent:"",
        disabled:false,
        expiredOTP:"none",
        forgotPassMail:"none",
        forgotPassMailDoesnotExist:"none",
        forgotPassMailInvalid:"none",
     
        displayOTP:"none",
        displayForgotMail:"block",
  
        emptyForgotPass:"none",
        invalidForgotpass:"none",
        emptyConfirmPass:"none",
        invalidConfirmPass:"none",
       
        passReset:"",
        Spin:false
     

    };
  }


  // componentDidMount(){
  //   // var info = this.props.data.allMysqlLeads.edges;
  //   // this.setState({infoarray:info})
     
  // }

  validateForgotPassEmail()
  {
   this.setState({ 
       forgotPassMail: "none",
       forgotPassMailDoesnotExist:"none",
       forgotPassMailInvalid: "none" 
     });
    
     //alert(this.state.alreadyhaveemail,"this is query result")
     var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
     var email = document.getElementById("forgotPassemail").value;
    
     if (email == "") {
       this.setState({ 
           forgotPassMailInvalid: "none",
           forgotPassMailDoesnotExist:"none",
           forgotPassMail: "block" 
       });
     } else {
       this.setState({ forgotPassMail: "none" });
       if (!pattern.test(email)) {
         this.setState({ forgotPassMailInvalid: "block" });
       } else {
         this.setState({ forgotPassMailInvalid: "none",
         email:email
         });
         
        }
      }
  }
  
  handleCancel(){
    this.setState({ visible: false,
      displayForgotMail:"block",
      displayOTP:"none",
      displayResetPass:"none"
   });
   document.getElementById("forgotpasswordform").reset();
  }

  showModal()
  {
      this.setState({visible:true,
        displayForgotMail:"block"
      })
    
  }

  onchangeOTP(){
    this.setState({
        invalidOTP:"none",
        expiredOTP:"none"
    })
}

validateForgotPass(){
    this.setState({
        emptyForgotPass:"none",
        invalidForgotpass:"none"
    })
    var pattern=/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
    var password=document.getElementById("newpass").value;
    if(password == "")
    {
      this.setState({emptyForgotPass:"block"});
    }
    else
    {
      this.setState({emptyForgotPass:"none"});
      if(!pattern.test(password))
      {
        this.setState({invalidForgotpass:"block"});
      }
      else
      {

        this.setState({invalidForgotpass:"none"});
      }
    }

}

validateConfirmPass(){
    this.setState({
        emptyConfirmPass:"none",
        invalidConfirmPass:"none"
    })
    var confirmpass = document.getElementById("newconfirmpass").value;
    var pass =document.getElementById("newpass").value;
    if(confirmpass=="")
    {
        this.setState({emptyConfirmPass:"block"})
    }
    else{
        this.setState({emptyConfirmPass:"none"})
        if(pass!==confirmpass){
            this.setState({invalidConfirmPass:"block"})
        }
    }
}

  notificationStatus(type, otpsent) {
    notification[type]({
      message: `${otpsent}`,
      duration: 3,
      btn:""
    });
  }

//-----------------------------------------resend otp--------------------------------//
resendOTP(){
  
    var otp = otpGenerator.generate(6, { alphabets: false, upperCase: false, specialChars: false });
    var now = moment(new Date());
    var nowplus10 = moment(now).add(3,'minutes');
    this.setState({
      invalidOTP:"none",
      expiredOTP:"none",
      OTP:otp,
      nowTime:nowplus10,
      otpSent:"OTP is sent to "+document.getElementById("forgotPassemail").value,
      disabled:true 
    }, () => {
     this.notificationStatus("success", this.state.otpSent);
    })
    var postData ={
      email:document.getElementById("forgotPassemail").value,
      OTP:otp
    }
  // console.log(postData,"postdata")
    axios.post("https://dashboard.nativecircle.in/wp-json/api/forgot-password",postData)
    .then(response=>{
     
       // console.log("email is sent",response.data)
    })
    .catch(err => {
     console.log(err);
   });
  
  
  }


//----------------------------------------end/------------------------------------//


  
  

  // ------------------------------------validate otp password----------------//
  validateOTP(){
    this.setState({
      invalidOTP:"none",
      expiredOTP:"none"
    })
    var otp =document.getElementById("otp").value;
    if(otp=="")
    {
      this.setState({invalidOTP:"block"})
    }
    else{
    var later = moment(new Date());
    var diff = moment(this.state.nowTime).diff(later,'minutes')
    //console.log(diff,"difference")
    if(diff<=3 && diff>=0 ){
     // console.log("yeah..it is less than 3")
  
     if(otp==this.state.OTP && this.state.invalidOTP=="none" && this.state.expiredOTP=="none"){
      this.setState({
        displayResetPass:"block",
        displayOTP:"none"
      })
    }
    else{
      this.setState({
        invalidOTP:"block"
      })
    }
    }
    else{
      //console.log("ohh no its too late")
      this.setState({
        expiredOTP:"block"
      })
    }
    }
  }
  //------------------------------------validate otp-------------------------//
//------------------------------------otp-code----------------------//
sendOTP(){
    this.setState({
        forgotPassMail:"none",
       
    
    })
  var email = 
  {
    email:document.getElementById("forgotPassemail").value
  }
  if (email.email == ""|| this.state.forgotPassMailDoesnotExist=="block"||this.state.forgotPassMailInvalid=="block") {
   // this.setState({forgotPassMail:"block"})
   this.validateForgotPassEmail()
    
  } 
  else{
    console.log(email,"bodydata")
  
    axios.post("https://dashboard.nativecircle.in/wp-json/api/login",email)
    .then(response=>{
      if(response.data.status==200)
      {

           this.setState({
             //visible:true,
             displayOTP:"block",
             displayForgotMail:"none",
             otpSent:"OTP is sent to "+email.email,
             disabled:true 
           }, () => {
            this.notificationStatus("success", this.state.otpSent);
          }
          )
           var otp = otpGenerator.generate(6, { alphabets: false, upperCase: false, specialChars: false });
           var now = moment(new Date());
           var nowplus10 = moment(now).add(3,'minutes');
           this.setState({
            OTP:otp,
            nowTime:nowplus10
          })
           var postData ={
             email:document.getElementById("forgotPassemail").value,
             OTP:otp
           }
          console.log(postData,"postdata")
           axios.post("https://dashboard.nativecircle.in/wp-json/api/forgot-password",postData)
           .then(response=>{
            
               console.log("email is sent",response.data)
           })
           .catch(err => {
            console.log(err);
          });
      }
      else{
        this.setState({forgotPassMailDoesnotExist:"block"});
      }
    })
    .catch(err => {
      console.log(err);
    });

  }


}
  //--------------------------otp-code-end---------------------------------------------//


  // ------------------------reset password code------------------------------------------//
  resetPassword(){
  
    if(document.getElementById("newpass").value==""||this.state.invalidForgotpass=="block"){
        this.validateForgotPass();
   }
   else if(document.getElementById("newconfirmpass").value==""||this.state.invalidConfirmPass=="block")
   {
       this.validateConfirmPass();
   }
   else{
       this.setState({
         invalidForgotpass:"none",
         invalidConfirmPass:"none"
       })
   var password = passwordHash.generate(document.getElementById("newpass").value)
   var updatedata = {
     email:document.getElementById("forgotPassemail").value,
     password:password
 
   }
 
 axios.post("https://dashboard.nativecircle.in/wp-json/api/update-password",updatedata)
 .then(response=>{
   console.log(response.data)
       this.setState({visible:false,
         passReset:"Password is reset",
         disabled:true,
         displayResetPass:"none",
         displayOTP:"none"
       }, () => {
        this.notificationStatus("success", this.state.passReset);
      
    
 })
 })
 .catch(err => {
   console.log(err);
 });
   }
 
 }
  //------------------------reset password code------------------------------------------//




  proceedtoLogin(){
    this.setState({Spin:true})
    var _this = this;
      var bodyFormData = new FormData();
  
      var email = document.getElementById("email").value;
     // console.log(typeof(email),"email type");
      var password=document.getElementById("password").value;
      
     // globalemail =email;
     // console.log(email+password )
      var stringify;
      
       if (email == ""  ) {
        this.validateEmail();
        this.setState({Spin:false})
   
      } 
      else if(password == "" )
      {
        this.validatePassword();
        this.setState({Spin:false})
      }
      else {
        // var infodata=this.props.data.allMysqlLeads.edges
        
      
        //   var checkdata=infodata.filter(element=>{
        //    if( element.node.email==email && (passwordHash.verify(password, element.node.password)))
        //   // if( element.node.email===email && password===element.node.password)
        //     {
              
        //       return email;
        //     }
        //   })
        
        // if(checkdata.length==0)
        // {
        //   this.setState({invalidPws:"block"});
        //   // alert(checkdata.length)
        //   // alert("failure")
        // }
        // else
        // {
        //   this.setState({invalidPws:"none"});
        //   window.sessionStorage.setItem("email",email);
        //   //this.setState({redirect:"block"})
        //   // alert(checkdata.length)
        //   //alert("sussss")
        //   window.sessionStorage.setItem("isLoggedIn",true);
        //   navigate("/",{state:{isLoggedIn:true}});
        // }
       
        var emailID = document.getElementById("email").value;
    var password = document.getElementById("password").value;

    var logindata ={
      email:emailID,
      password : password
    }

    axios.post("https://dashboard.nativecircle.in/wp-json/api/login",logindata)
    .then(response=>{
    
      if(response.data.status==200){
           var pass = response.data.message[0].password;
          if( passwordHash.verify(password,pass)== true){
            //login success
              this.setState({Spin:false})
               this.setState({invalidPws:"none"});
               this.setState({alreadyhaveemail:"none"},() => {
                
               });
              window.sessionStorage.setItem("email",email);
              window.sessionStorage.setItem("isLoggedIn",true);
              navigate("/");
             //console.log("login successs")
           
          }
          else{
            // set the state to display the message "invalid password"
            this.setState({invalidPws:"block",Spin:false});
           // console.log("invalid password")
          }
      }
      else{
        // set the state to display the message "this email is does not exist"
        this.setState({alreadyhaveemail:"block",Spin:false});
       // console.log("this email does not exist")
      }
    })
   
    .catch(err => {
     // console.log(err);
    });


        
     
      

  
      }
  }




  validateEmail() {

      this.setState({displayEmailInvalid:"none", alreadyhaveemail:"none",displayEmail:"none",Spin:false});



    //alert(this.state.alreadyhaveemail,"this is query result")
    var pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var email = document.getElementById("email").value;
    
    if (email == "") {
      this.setState({ displayEmailInvalid: "none" });
      this.setState({alreadyhaveemail:"none"});
      this.setState({ displayEmail: "block" });
    } else {
      this.setState({ displayEmail: "none" });
      if (!pattern.test(email)) {
        this.setState({ displayEmailInvalid: "block" });
      } else {
        this.setState({ displayEmailInvalid: "none" });
        
       
        // console.log(this.state.infoarray,"info");
        

        // var testdata=this.state.infoarray.filter(ele=>{
        //   if( ele.node.email==email)
        //   {
        //     return email;
        //   }

        // })
        // console.log(testdata);
        // if(testdata.length==0)
        // {
        
        //   this.setState({alreadyhaveemail:"block"});
          
        // }
        // else
        // {
        //   this.setState({alreadyhaveemail:"none"});
        // }
       }
     }
   }

validatePassword()
  {
    this.setState({Spin:false})
    var password=document.getElementById("password").value;
    if(password == "")
    {
      this.setState({displayPassword:"block"});
    }
    else
    {
      this.setState({displayPassword:"none"});
    }
  }




  render() {
    
    return (
      <div>
           <Spin  tip="Loading..."
          spinning={this.state.Spin}
          wrapperClassName="spinClass">
<Modal
          visible={this.state.visible}
          title="Password reset"
          onOk={() => this.sendOTP()}
          onCancel={() => this.handleCancel()}
          footer={null}
          maskClosable={false}
         
        >
        <form action="javascript:void(0)" id="forgotpasswordform">
            <div className="row form-group">
            
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15" style={{display:this.state.displayForgotMail}}>
                <label htmlFor="email" className="NC-formlabel">
                  Email ID *
                </label>
                <input
                  className="form-control NC-fullWidth formcontrolColorChange"
                  type="email"
                  id="forgotPassemail"
                  // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                 // defaultValue={this.state.email}
                 
                  //readOnly
                  onChange={() => this.validateForgotPassEmail()}
                />
                  <p
                  style={{
                    display: this.state.forgotPassMail,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                * This field is required
                </p>
                <p
                  style={{
                    display: this.state.forgotPassMailDoesnotExist,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                * This email does not exists
                </p>
                <p
                  style={{
                    display: this.state.forgotPassMailInvalid,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                * The Entered Email id is not valid
                </p>
                
                    
              </div>
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15" style={{display:this.state.displayForgotMail}}>
              <button class="forgotpwsemailbtn"  onClick={()=>this.sendOTP()}>Next</button>
              </div>


              <div className="col-md-12 col-xs-12 col-sm-12 padTop15" style={{display:this.state.displayOTP}}>
                <label  className="NC-formlabel">
                  Enter OTP 
                </label>
                <input
                  className="form-control NC-fullWidth formcontrolColorChange"
                  type="number"
                  id="otp"
                  // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                 
                  //onInput= {() => this.validateOTP()}
                  onChange={() => this.onchangeOTP()}
                />
                    <p
                  style={{
                    display: this.state.invalidOTP,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                * Please enter valid OTP
              
                </p> 
                <p
                  style={{
                    display: this.state.expiredOTP,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
               
                * This OTP has expired, request a new OTP to continue

                </p> 
                <span><a class="resendotpbtn" onClick={()=>this.resendOTP()}>Resend OTP?</a></span>
              </div>

              <div className="col-md-12 col-xs-12 col-sm-12 padTop15" style={{display:this.state.displayOTP}}>
              <button class="forgotpwsemailbtn" onClick={()=>this.validateOTP()}>Submit</button>
              </div>
             
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15" style={{
                display: this.state.displayResetPass,
              }}>
                <label  className="NC-formlabel">
                 New Password
                </label>
                <input
                  className="form-control NC-fullWidth formcontrolColorChange"
                  type="password"
                  id="newpass"
                  // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  
                  
                  onChange={() => this.validateForgotPass()}
                />
                    
              </div>
              <p
                  style={{
                    display: this.state.emptyForgotPass,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                * Please enter new password
              
                </p> 
                <p
                  style={{
                    display: this.state.invalidForgotpass,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                * Must contain at least one number and one uppercase and lowercase letter,
                       and at least 8 or more characters
              
                </p> 

              
              <div className="col-md-12 col-xs-12 col-sm-12 padTop15" style={{
                display: this.state.displayResetPass,
              }}>
                <label  className="NC-formlabel">
                 Confirm Password
                </label>
                <input
                  className="form-control NC-fullWidth formcontrolColorChange"
                  type="password"
                  id="newconfirmpass"
                  // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  onChange={() => this.validateConfirmPass()}
                />
                    
              </div>
              <p
                  style={{
                    display: this.state.emptyConfirmPass,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                * This field is required
              
                </p> 
                <p
                  style={{
                    display: this.state.invalidConfirmPass,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                * Password does not match
              
                </p> 


              <div className="resetpasswordbtndiv"
              style={{
                display: this.state.displayResetPass,
                
              }}
              >
              <button 
              className="resetbuttonpassword"
              onClick={() => this.resetPassword()}  
              >Reset Password
             
                </button>
                </div>

              

            </div>
          </form>
          </Modal>
    
          {/* <HeaderLight/> */}
      <div className="loginBackground">
      
      <div class="container signup-container-mobile">

      <div className="wrapper">
      <div className="loginFormcontent col-sm-12 col-xs-12 col-md-4">
      <a href="/">
      <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1569477874/Logo_login_pb5x9z.png" class="user"></img></a>
        <form action="javascript:void(0)" id="form">
        <p className="loginheading loginbtnname">Log in</p>
             <div className="insideForm">
             
             
              <div className="col-md-12 col-xs-12 col-sm-12 mobsize">
              <p
                  style={{
                    display: this.state.invalidPws,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                *  invalid password
                </p> 
                <label htmlFor="email" className="loginLabel">
                Email
                </label>
                <input
                  className="form-control loginEntryField"
                  type="email"
                  id="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                  
                  onInput={() => this.validateEmail()}
                />
                <p
                  style={{
                    display: this.state.displayEmail,
                    fontSize:"13px",
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                   This field is required
                </p>
                 <p
                  style={{
                    display: this.state.displayEmailInvalid,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                *  The Entered Email id is not valid
                </p> 
                <p style={{
                    display: this.state.alreadyhaveemail,
                    color: "red",
                    fontSize:"13px",
                    marginTop: "10px"
                  }}
                >
                   * this email does not  exists
                </p>
             </div>
            
             <div className="col-md-12 col-xs-12 col-sm-12 mobsize">
             <label htmlFor="password" className="loginLabel">
               Password
                </label>
                <input
                  className="form-control loginEntryField"
                  type="password"
                  id="password"
                  
                  onInput={() => this.validatePassword()}
                />
                  <p> <a class="forgetpws"  onClick={()=>this.showModal()} >Forgot password ?</a></p>

                <p
                  style={{
                    display: this.state.displayPassword,
                    fontSize:"13px",
                    color: "red",
                    marginTop: "10px"
                  }}
                >
                  * This field is required
                </p>
               
             </div>

            <div className="loginbtndiv">

              <button 
              className="loginBtn"
              onClick={() => this.proceedtoLogin()}
                     
              >Log in
              <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg" class="loginarrow"/>
                </button>
                <Link  style={{
                    display: this.state.redirect,
                  }} to ={"/"}/>
              </div>
              {/* <a className="accountQuestion" onClick={()=>this.sendOTP()} >Forgot Password?</a> */}
             
              <p className="accountQuestion">Don’t have an account ?<span> <a className="signupclass" href="/registration/">Sign up.</a></span></p>
           
             <a class="continuewtoutlogin"
                href="/">
               Continue without login
               
             </a>
              
           </div>

          </form>
      </div>
        </div>

        </div>
       
          </div>
          <Footer />
          </Spin>
        </div>
    );
  }
}
export default Login;
// export const pageQuery = graphql`
// query MyQuery {
//   allMysqlLeads {
//     edges {
//       node {
//         email
//         password
//       }
//     }
//   }
// }
// `;

